import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// Paste this config into public/firebase-messaging-sw.js worker
import firebaseConfig from "./firebase-config.json";

export let messaging;

const initializeFirebase = async () => {
  try {
    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
  } catch (error) {
    console.error("Firebase initialization error:", error);
  }
};

initializeFirebase();
