import { call, put, takeEvery, takeLatest, all, take } from "redux-saga/effects";


import AppActionTypes from "./AppActionTypes";
import AppActionCreators from "./AppActionCreators";
import I from 'immutable'
import log from '../../helpers/logger'
import { TOAST } from "../../helpers/NotificationHelper";
import ApiHelper from "../../helpers/ApiHelper";
import { clearCookie, clearCookies, isFunction, setCookies } from "../../helpers/utils";
import { navigateToUrl } from "../../helpers/StoreHelper";



const transformResponse = response => {
  let res = response
  if (res.status === false) {
    if (res.msg === "Invalid Token") {
      clearCookies()
      navigateToUrl('/login', true)
    }
  } else if (res.status === true && !!res.msg) {
    TOAST.success(res.msg)
  }


  return res
}

//ABEMART

function* fetchBanner(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.banner)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* fetchCategoryList(action) {
  const { martId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.categoryList, martId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchDeliverySlots(action) {
  const { martId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.deliveryslots, martId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}



function* fetchOfferList(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.offerList)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchOrdersList(action) {
  const { pageno, search, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.ordersList, pageno, search)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchOrder(action) {
  const { orderId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchOrder, orderId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchReviews(action) {
  const { variantId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchReviews, variantId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* submitReviews(action) {
  const { orderId, orderItemId, review, rating, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.submitReviews, orderId, orderItemId, review, rating)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* deleteReview(action) {
  const { reviewId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.deleteReview, reviewId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* editReview(action) {
  const { reviewId, review, rating, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.editReview, reviewId, review, rating)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* cancelOrder(action) {
  const { orderId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.cancelOrder, orderId)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* searchList(action) {
  const { search, cb, hideLoader } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.searchList, search)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    // log(e)
    console.warn(e);
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* createGuestLogin(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.createGuestLogin)
    yield put(AppActionCreators.setProfile(resData.data))

    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    console.warn(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* profileData(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.profileData)
    // if (!!resData.error_code) {
    yield put(AppActionCreators.setProfile(resData))
    // }

    let resData1 = yield call(ApiHelper.fetchCartV2)
    yield put(AppActionCreators.setCart(resData1))

    // let resData2 = yield call(ApiHelper.fetchWishlist)
    // yield put(AppActionCreators.setWishList(resData2))

    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    console.warn(e.response)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false, e.response.status)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* profileDataAll(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.profileDataAll)
    // if (!!resData.error_code) {

    yield put(AppActionCreators.setProfileDataAll(resData))
    // }

    // let resData1 = yield call(ApiHelper.fetchCart)
    // yield put(AppActionCreators.setCart(resData1))

    // let resData2 = yield call(ApiHelper.fetchWishlist)
    // yield put(AppActionCreators.setWishList(resData2))

    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    console.warn(e.response)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false, e.response.status)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* getOtp(action) {
  const { phone, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.getOtp, phone)
    if (resData.data) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* validateOtp(action) {
  const { phone, otp, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.validateOtp, phone, otp)
    yield put(AppActionCreators.setProfile(resData.data, phone))

    if (resData.data && resData.data.zip) {
      setCookies('pincode', resData.data.zip)
    } else {
      clearCookie('pincode')
    }

    if (resData.status) {
      TOAST.info(resData.data.message)
    }

    let resData1 = yield call(ApiHelper.fetchCartV2)
    yield put(AppActionCreators.setCart(resData1))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e.response)
    if (e.statusText) {
      TOAST.error(e.statusText)
    } else {
      TOAST.error(e.message)
    }
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* setNewNameEmail(action) {
  const { username, email, phone, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.setProfile, username, email, phone)

    if (resData.status) {
      TOAST.info(resData.message)
    }
    yield put(AppActionCreators.setProfile(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    // TOAST.error(e.statusText)
    if (isFunction(cb)) {
      yield call(cb, false, e.data)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* updateProfile(action) {
  const { name, phone, email, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.setProfile, name, email, phone)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
    yield put(AppActionCreators.setProfile(resData))
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* verifyEmail(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.verifyEmail)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchSearchResult(action) {
  const { mode, id, page, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchSearchResult, mode, id, page)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchSearchProductResult(action) {
  const { mode, id, page, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchSearchProductResult, mode, id, page)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    if(e?.status == 422 && e?.data?.availability_notice){ //availability_notice
      if (isFunction(cb)) {
        yield call(cb, e?.data)
      }
    }else{
      TOAST.error(e.message)
      if (isFunction(cb)) {
        yield call(cb, false)
      }
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchOffer(action) {
  const { id, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchOffer, id)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    if (e.status != 404) {
      TOAST.error(e.message)
    }
    if (isFunction(cb)) {
      yield call(cb, false, e)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchProductDetails(action) {
  const { id, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchProductDetails, id)

    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    if (e.status != 404) {
      TOAST.error(e.message)
    }
    if (isFunction(cb)) {
      yield call(cb, false, e)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchAddressList(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchAddressList)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    yield put(AppActionCreators.setAddressList(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchAddress(action) {
  const { addressId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchAddress, addressId)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* deleteAddress(action) {
  const { addressId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.deleteAddress, addressId)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    yield put(AppActionCreators.setAddressList(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchStates(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchStates)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchZipcodes(action) {
  const { martId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchZipcodes, martId)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* createOrUpdateAddress(action) {
  const { addressId, full_name, phone, address_1, address_2, city, stateId, pincode, primary, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.createOrUpdateAddress, addressId, full_name, phone, address_1, address_2, city, stateId, pincode, primary)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    yield put(AppActionCreators.setAddressList(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false, e.data)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* createOrUpdateAddress1(action) {
  const { address, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.createOrUpdateAddress1, address)
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    yield put(AppActionCreators.setAddressList(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}


function* fetchCart(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchCartV2)
    yield put(AppActionCreators.setCart(resData))
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchCartV2(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchCartV2)
    yield put(AppActionCreators.setCart(resData))
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* add2Cart(action) {
  const { batch_id, qty, cartItemId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData
    // if (qty > 0) {
    resData = yield call(ApiHelper.add2Cart, batch_id, qty)
    // } else {
    // resData = yield call(ApiHelper.removeCartItem, cartItemId)
    // }

    yield put(AppActionCreators.setCart(resData))

    window.dataLayer?.push({ 'ecommerce.add': null });
    if (resData?.net_total_amt > 0) {
      let items = resData.cart_items?.map(x => (x.cart_items)).flat(1)
      let ii = items.map((x, i) => ({
        item_id: x?.variant?.variant_sku,
        item_name: x?.variant?.product?.product_name,
        // affiliation: "Google Merchandise Store",
        // coupon: "SUMMER_FUN",
        discount: x?.cart_item_discount,
        index: i,
        // item_brand: "Google",
        // item_category: "Apparel",
        // item_category2: "Adult",
        // item_category3: "Shirts",
        // item_category4: "Crew",
        // item_category5: "Short sleeve",
        // item_list_id: "related_products",
        // item_list_name: "Related Products",
        item_variant: x?.variant?.variant_name,
        // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
        price: x?.cart_item_gross_rate,
        quantity: x.cart_item_quantity
      }))

      window.dataLayer?.push({
        event: "add_to_cart",
        ecommerce: {
          add: {
            currency: "INR",
            value: resData?.net_total_amt,
            items: ii
          }
        }
      })
    }
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    if (e.data?.message) {
      TOAST.error(e.data?.message)
    } else {
      TOAST.error(e.message)
    }
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}


function* fetchWishList(action) {
  const { page, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchWishList, page)
    // yield put(AppActionCreators.setWishList(resData))
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchAvailableTimeSlot(action) {
  const { orderDeliveryMethodId, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchAvailableTimeSlot, orderDeliveryMethodId)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* setTimeSlot(action) {
  const { deliveryTimeId, orderDeliveryMethodId,deliveryTimeCount, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.setTimeSlot, deliveryTimeId, orderDeliveryMethodId,deliveryTimeCount)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* updateWishList(action) {
  const { variant_id, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.updateWishList, variant_id)
    yield put(AppActionCreators.setWishList(resData))
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* setCartCheckout(action) {
  const { voucher, cb } = action.payload


  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.setCartCheckout, voucher)

    if (resData?.order_id) {
      window.dataLayer?.push({ 'ecommerce.checkout': null });
      if (resData?.net_amount > 0) {
        let items = resData.delivery_methods?.map(x => (x.items)).flat(1)
        let ii = items.map((x, i) => ({
          item_id: x?.data?.variant_sku,
          item_name: x?.order_item_product_title,
          discount: x?.order_item_discount,
          index: i,
          item_variant: x?.order_item_variant_name,
          price: x?.order_item_rate,
          quantity: x.order_item_quantity
        }))
        window.dataLayer?.push({
          event: "checkout",
          ecommerce: {
            checkout: {
              currency: "INR",
              value: resData?.net_amount,
              items: ii
            }
          }
        })
      }
    }



    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e?.data?.message ?? "Error")
    if (isFunction(cb)) {
      yield call(cb, false, e.status)
    }
  }
  finally {
    yield put(AppActionCreators.hideLoader())
  }
}


function* fetchCartCheckout(action) {
  const { cb } = action.payload
  try {
    // yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchCartCheckout)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  }
  // finally {
  //   yield put(AppActionCreators.hideLoader())
  // }
}

function* setPaymentStatus(action) {
  const { orderId, paymentStatus, paymentId, paymentType, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.setPaymentStatus, orderId, paymentStatus, paymentId, paymentType)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  }
  finally {
    yield put(AppActionCreators.hideLoader())
  }
}


function* logout(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.logout)
    yield put(AppActionCreators.setProfile(I.Map()))
    if (resData.status) {
      TOAST.info(resData.data.message)
    }
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* fetchPage(action) {
  const { key, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchPage, key)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

function* checkVoucher(action) {
  const { code, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.checkVoucher, code)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* notifyMe(action) {
  const { id, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.notifyMe, id)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log('e',e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* fetchNotifyStatus(action) {
  const { id, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchNotifyStatus, id)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log('e',e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}
function* updateFCM(action) {
  const { fcm, cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.updateFCM, fcm)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}


function* fetchDayFreshDeliverySlots(action) {
  const { cb } = action.payload
  try {
    yield put(AppActionCreators.showLoader())
    let resData = yield call(ApiHelper.fetchDayFreshDeliverySlots)
    // transformResponse(resData)
    if (isFunction(cb)) {
      yield call(cb, resData)
    }
  } catch (e) {
    log(e)
    TOAST.error(e.message)
    if (isFunction(cb)) {
      yield call(cb, false)
    }
  } finally {
    yield put(AppActionCreators.hideLoader())
  }
}

export default function* appSaga() {
  yield all([

    //ABEMART
    takeLatest(AppActionTypes.REQUEST_FETCH_BANNER, fetchBanner),
    takeLatest(AppActionTypes.REQUEST_FETCH_CATEGORY_LIST, fetchCategoryList),
    takeLatest(AppActionTypes.REQUEST_FETCH_DELIVERY_SLOTS, fetchDeliverySlots),
    takeLatest(AppActionTypes.REQUEST_FETCH_OFFER_LIST, fetchOfferList),
    takeLatest(AppActionTypes.REQUEST_FETCH_ORDERS_LIST, fetchOrdersList),
    takeLatest(AppActionTypes.REQUEST_FETCH_ORDER, fetchOrder),
    takeLatest(AppActionTypes.REQUEST_FETCH_REVIEWS, fetchReviews),
    takeLatest(AppActionTypes.REQUEST_SUBMIT_REVIEWS, submitReviews),
    takeLatest(AppActionTypes.REQUEST_DELETE_REVIEWS, deleteReview),
    takeLatest(AppActionTypes.REQUEST_EDIT_REVIEWS, editReview),
    takeLatest(AppActionTypes.REQUEST_CANCEL_ORDER, cancelOrder),
    takeLatest(AppActionTypes.REQUEST_FETCH_ADDRESS_LIST, fetchAddressList),
    takeLatest(AppActionTypes.REQUEST_FETCH_SEARCH_RESULT, fetchSearchResult),
    takeLatest(AppActionTypes.REQUEST_FETCH_SEARCH_PRODUCT_RESULT, fetchSearchProductResult),
    takeLatest(AppActionTypes.REQUEST_FETCH_OFFER, fetchOffer),
    takeLatest(AppActionTypes.REQUEST_FETCH_PRODUCT_DETAILS, fetchProductDetails),
    takeLatest(AppActionTypes.REQUEST_FETCH_ADDRESS, fetchAddress),
    takeLatest(AppActionTypes.REQUEST_CREATE_OR_UPDATE_ADDRESS, createOrUpdateAddress),
    takeLatest(AppActionTypes.REQUEST_CREATE_OR_UPDATE_ADDRESS1, createOrUpdateAddress1),
    takeLatest(AppActionTypes.REQUEST_FETCH_STATES, fetchStates),
    takeLatest(AppActionTypes.REQUEST_FETCH_ZIPCODES, fetchZipcodes),
    takeLatest(AppActionTypes.REQUEST_DELETE_ADDRESS, deleteAddress),
    takeLatest(AppActionTypes.REQUEST_FETCH_SEARCH_LIST, searchList),
    takeLatest(AppActionTypes.REQUEST_CREATE_GUEST_LOGIN, createGuestLogin),
    takeLatest(AppActionTypes.REQUEST_FETCH_OTP_DATA, getOtp),
    takeLatest(AppActionTypes.REQUEST_VALIDATE_OTP_DATA, validateOtp),
    takeLatest(AppActionTypes.REQUEST_CREATE_NAME_EMAIL, setNewNameEmail),
    takeLatest(AppActionTypes.REQUEST_FETCH_PROFILE_DATA, profileData),
    takeLatest(AppActionTypes.REQUEST_FETCH_PROFILE_DATA_ALL, profileDataAll),
    takeLatest(AppActionTypes.REQUEST_UPDATE_PROFILE, updateProfile),
    takeLatest(AppActionTypes.REQUEST_VERIFY_EMAIL, verifyEmail),
    takeLatest(AppActionTypes.REQUEST_NOTIFY_ME, notifyMe),
    takeLatest(AppActionTypes.REQUEST_NOTIFY_STATUS, fetchNotifyStatus),

    takeLatest(AppActionTypes.REQUEST_FETCH_WISH_LIST, fetchWishList),
    takeLatest(AppActionTypes.REQUEST_UPDATE_WISH_LIST, updateWishList),
    takeLatest(AppActionTypes.REQUEST_FETCH_AVAILABLE_TIME_SLOT, fetchAvailableTimeSlot),
    takeLatest(AppActionTypes.REQUEST_SET_TIME_SLOT, setTimeSlot),
    takeLatest(AppActionTypes.REQUEST_FETCH_CART, fetchCart),
    takeLatest(AppActionTypes.REQUEST_FETCH_CART_V2, fetchCartV2),
    takeLatest(AppActionTypes.REQUEST_SET_CART_CHECKOUT, setCartCheckout),
    takeLatest(AppActionTypes.REQUEST_ADD_2_CART, add2Cart),
    takeLatest(AppActionTypes.REQUEST_SET_PAYMENT_STATUS, setPaymentStatus),
    takeLatest(AppActionTypes.REQUEST_LOGOUT, logout),
    takeLatest(AppActionTypes.REQUEST_PAGE, fetchPage),
    takeLatest(AppActionTypes.REQUEST_CHECK_VOUCHER, checkVoucher),
    takeLatest(AppActionTypes.REQUEST_FETCH_DAY_FRESH_DELIVERY_SLOTS, fetchDayFreshDeliverySlots),

    takeLatest(AppActionTypes.REQUEST_UPDATE_FCM, updateFCM),
  ]);
}
