import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./fireConfig";
import { notification } from "antd";
import { notificationAction } from "../helpers/utils";
import firebaseConfig from "./firebase-config.json";

async function foregroundNotification() {
    onMessage(messaging, (payload) => {
        notification.info({
            message: payload.notification.title,
            description: payload.notification.body,
            placement: "topRight",
            duration: 15,
            onClick: () => {
                const path = notificationAction(payload?.data?.page);
                if (path) {
                    window.location.href = path;
                }
            },
        });
    });
}

async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {

        foregroundNotification();

        let token;
        try {
            token = await getToken(messaging, {
                vapidKey: firebaseConfig?.vapid,
            });
            // console.log("token", token);
            
            return token;
        } catch (err) {
            if (err.code === "messaging/notifications-blocked") {
                console.log("Please allow notifications in your browser settings");
            } else if (err.code === "messaging/failed-service-worker-registration") {
                console.log("Service Worker registration failed");
            } else {
                console.log("Error getting FCM token", err);
            }
        }

    }
    return null;
}




export { requestPermission, foregroundNotification };